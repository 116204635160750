input:focus {
  outline: 1px solid white;
}
.input {
  width: 100%;
  height: 60px !important;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  /* border: 1px solid #ced4da; */
  border: 1px solid #FFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.inputUpper {
  width: 100%;
  height: 60px !important;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  /* border: 1px solid #ced4da; */
  border: 1px solid #FFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* text-transform: uppercase; */
}
.labels {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}
.labelsMobile {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}
.hr {
  flex: 1;
  border-width: 0.5;
  text-align: center;
  margin-left: 5px;
}
.inputUpper {
  text-transform: uppercase;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
