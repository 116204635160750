@import "../style/mixins";
@import "../style/utilities";

.formContainer {
  margin-right: 20px;
  margin-left: 20px;

  &__row {
    display: flex;
  }
  &__flexOne {
    flex: 1;
    &__margin {
      flex: 1;
      margin-right: 10px;
    }
  }

  &__addressRow {
    display: flex;
  }
}
