.headerContainerWithLogo {
  display: flex;
  background-color: white;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.headerContainerWithLogo__imgContainer {
  margin-left: 25px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.headerContainer__arrowContainer {
  padding-left: 25px;
}

.languageSwitchContainer {
  display: flex;
}
.languageSwitchContainer__currentLanguage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32px;
  margin-right: 25px;
  color: #005a8c;
}
