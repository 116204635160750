$marginXL: 20px;
$paddingXL: 20px;
$paddingXXL: 30px;

$fontXXS: 10px;
$fontXS: 12px;
$fontS: 14px;
$fontM: 16px;
$fontL: 20px;

$beppoRed: rgb(204, 6, 5);
$beppoBlue: rgb(0, 90, 146);
$beppoGray: rgb(99, 107, 109);
$mobileBackground: rgb(242, 242, 242);
$backgroundColor: rgb(242, 242, 242);

$fontGilroyRegular: Gilroy-Regular;
$fontGilroyMeduim: Gilroy-Medium;
