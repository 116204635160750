@import "../style/mixins";
@import "../style/utilities";

/* MOBILE */

.mainContainerMobile {
  display: flex;
  flex-direction: column;
  background-color: $mobileBackground;
  min-height: 100vh;
}

.headingContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 20px;

  &__title {
    font-family: $fontGilroyRegular;
    font-size: $fontL;
    font-weight: 600;
    color: $beppoRed;
    margin-left: 5px;
  }
}

.formMobile {
  margin-left: $marginXL;
  margin-right: $marginXL;
  margin-bottom: $marginXL;

  &__paragraphStyle {
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    margin-bottom: 10px;
    color: #444444;
  }
}

/* DESKTOP */

.mainContainerDesktop {
  height: 100vh;
  background: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);

  .formDesktop {
    top: 120px;
    left: 10%;
    right: 30%;
    position: absolute;

    &__paragraphStyle {
      font-family: $fontGilroyMeduim;
      font-size: $fontM;
      color: #444444;
    }
    &__ticketForm {
      width: 400px;
      padding-top: 30px;
      text-align: left;
    }
    &__blockElement {
      display: block;
    }
  }
}

/* BOTH */

.beppoLink {
  cursor: pointer;
  font-weight: 600;
  color: #005a8c;
}

.errorMessage {
  color: $beppoRed;
}

.versionBox {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: $fontXXS;
}
