@import "../style/mixins";
@import "../style/utilities";

.qrContainer {
  display: flex;
  flex-direction: column;

  &__title {
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    font-weight: 600;
    color: #000;
  }
  &__box {
    position: relative;
    align-self: center;
    max-width: 150px;
    max-height: 150px;
    margin-top: 25px;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 20px;

  &__heading {
    font-family: $fontGilroyRegular;
    font-size: $fontM;
    line-height: 20px;
    font-weight: 700;
    color: #444444;
    margin-bottom: 10px;
  }
  &__paragraphDesktop {
    display: flex;
    margin-bottom: 3px;
    font-family: $fontGilroyRegular;
    font-size: $fontM;
  }
  &__paragraphBold {
    display: flex;
    justify-content: flex-end;
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    line-height: 21px;
    font-weight: 700;
    align-items: flex-end;
    color: #444444;
    overflow: hidden;
  }
  &__horizontalLine {
    flex: 1;
    align-self: center;
    border-bottom: 1px solid #c7c7c7;
    margin-right: 10px;
    margin-left: 10px;
  }
  &__infoTextStyle {
    font-family: $fontGilroyMeduim;
    color: $beppoRed;
  }
}
.qrIcon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
}
