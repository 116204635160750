@import "../style/mixins";
@import "../style/utilities";

.form {
  width: -webkit-fill-available;
  margin-left: 25px;
  margin-right: 30px;
}

.qrContainer {
  display: flex;
  flex-direction: column;

  &__title {
    font-family: Gilroy-Medium;
    font-size: $fontM;
    font-weight: 700;
    color: #000;
  }
  &__box {
    position: relative;
    align-self: center;
    margin-top: 25px;
  }
  &__icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  &__heading {
    font-family: Gilroy-Regular;
    font-size: $fontM;
    font-weight: 700;
    color: #444444;
  }
  &__paragraphMobile {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
    font-family: $fontGilroyRegular;
    font-size: $fontM;
    line-height: 21px;
    color: #444444;
  }
  &__paragraphBold {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    font-weight: 700;
    color: #444444;
    overflow: hidden;
  }
  &__horizontalLine {
    flex: 1;
    align-self: center;
    border-bottom: 1px solid #c7c7c7;
    margin-right: 10px;
    margin-left: 10px;
  }
}
