@import "../style/mixins";
@import "../style/utilities";

.form__flex {
  display: flex;
  justify-content: space-between;

  &__margin {
    margin-right: 10px;
  }
}
