.headingWithBeppoLogo {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.hairlineHeading {
  width: 13px;
  height: 4px;
  border-radius: 2.5px;
  background-color: rgb(199, 199, 199);
  align-self: center;
  margin-right: 7px;
}
.languageSwitchContainer {
  display: flex;
}
.currentLanguage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32px;
  margin-right: 30px;
}
.imgTranslateContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
}