@import "../../style/mixins";
@import "../../style/utilities";

.formMobile {
  margin: 0 20px 20px 20px;
  &__paragraph {
    display: flex;
    margin-bottom: 3px;
    margin-top: 0px;
    font-family: $fontGilroyRegular;
    font-size: $fontM;
    line-height: 21px;
  }
  &__title{
  }
  &__paragraphBold {
    display: flex;
    justify-content: flex-end;
    text-align: end;
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    font-weight: 700;
    color: #444444;
    overflow: hidden;
  }
  &__paragraphDesc {
    margin-bottom: 3px;
    margin-top: 20px;
    font-family: $fontGilroyRegular;
    font-size: $fontM;
    text-align: justify;
  }
  &__mobileLinks {
    color: #005a8c;
    font-family: $fontGilroyMeduim;
    font-weight: bold;
  }
  &__termsRest {
    font-family: $fontGilroyRegular;
    font-size: $fontM;
  }
  &__termsLink {
    color: #005a92;
    font-family: $fontGilroyMeduim;
    font-weight: bold;
    font-size: $fontM;
  }
}
.horizontalLine {
  flex: 1;
  align-self: center;
  border-bottom: 1px solid #c7c7c7;
  margin-right: 15px;
  margin-left: 15px;
}
