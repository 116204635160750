@import "../style/mixins";
@import "../style/utilities";

.form__flex {
  display: flex;
  justify-content: space-between;
}

.columnDirectionFirstName {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.columnDirectionLastName {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
