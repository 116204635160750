@import "../style/mixins";

.hairline {
  width: 13px;
  height: 4px;
  border-radius: 2.5px;
  background-color: rgb(199, 199, 199);
  align-self: center;
  margin-right: 7px;
}

.headerLine {
  position: absolute;
  width: 10px;
  height: 4px;
  background-color: #c7c7c7;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  top: 30px;
  left: 0px;
}

.modalTitle {
  font-size: $fontL;
  font-family: $fontGilroyRegular;
  font-weight: 600;
  margin-left: 20px;
  padding-bottom: 14px;
}
.mobileTitle {
  font-family: $fontGilroyRegular;
  font-size: $fontL;
  font-weight: 600;
  margin-left: 5px;
  color: #444444;
}

.input {
  width: 100%;
  height: 60px !important;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: $fontGilroyRegular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #fff;
  margin-bottom: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.textarea {
  width: -webkit-fill-available;
  min-height: 100px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 15px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  /* border: 1px solid #ced4da; */
  border: 1px solid #fff;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
%btnMobile {
  background-color: $beppoBlue;
  padding-left: 10px;
  margin-bottom: $marginXL;
  height: 70px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
}

.btnMobilePrimary {
  @extend %btnMobile;
  border-color: $beppoBlue;
  background-color: $beppoBlue;
}
.btnMobileWhite {
  @extend %btnMobile;
  border-color: white;
  background-color: white;
  box-shadow: 1px 1px 5px white;
}

.btnSubmitMobileDisabled {
  @extend %btnMobile;
  flex-direction: row;
  margin-top: 0px;
  margin-right: 40px;
  margin-left: 40px;
  height: 60px;
  border-color: rgb(199, 199, 199);
  background: rgb(199, 199, 199);
  color: #fff;
  display: inline-block;
  border: 1px solid transparent;
  font-family: "Gilroy-Medium";
  font-size: 16px;
}

.btnSubmitMobile {
  @extend %btnMobile;
  flex-direction: row;
  margin-top: 0px;
  margin-right: 40px;
  margin-left: 40px;
  height: 60px;
  border-color: $beppoBlue;
  background: $beppoBlue;
  color: #fff;
  display: inline-block;
  border: 1px solid transparent;
  font-family: "Gilroy-Medium";
  font-size: 16px;
}

%btnText {
  font-family: $fontGilroyMeduim;
  font-size: $fontM;
  line-height: 24px;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 0px;
}
.btnTextBlue {
  @extend %btnText;
  color: $beppoBlue;
}
.btnTextWhite {
  @extend %btnText;
  color: white;
}

%btn {
  margin-bottom: $marginXL;
  height: 50px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  cursor: pointer !important;
}

.btnPrimary {
  @extend %btn;
  border-color: $beppoBlue;
  background-color: $beppoBlue;
}

.btnWhite {
  @extend %btn;
  border-color: white;
  background-color: white;
  box-shadow: 1px 1px 5px white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btnSubmitDesktop {
  @extend %btn;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 50px;
  border-color: $beppoBlue;
  background: $beppoBlue;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-family: "Gilroy-Medium";
  color: white;
}
.btnSubmitDesktopDisabled {
  @extend %btn;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 140px;
  height: 50px;
  border-radius: 4px;
  border-color: rgb(199, 199, 199);
  background: rgb(199, 199, 199);
  color: #fff;
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-family: "Gilroy-Medium";
  color: white;
}

.modalStyle {
  width: 100%;
  max-width: 500px;
  border-radius: 4px;
}
@media (min-width: 1545px) {
  .modalStyle {
    width: 20%;
    min-width: 450px;
    border-radius: 4px;
  }
}
.modalStyleCloseButton {
  outline-color: transparent;
  outline: none;
  box-shadow: none;
}
.modalStyleCloseIcon {
  fill: red;
  margin-top: 5px;
  margin-right: 20px;
}

.buttonMobile {
  margin-top: 25px;
  text-align: center;
  margin-right: $marginXL;
  margin-left: $marginXL;
  display: flex;
  justify-content: center;
}
.buttonDesktop {
  margin-top: 25px;
  text-align: center;
  margin-right: 20%;
  margin-left: 20%;
  display: flex;
  justify-content: center;
}
.buttonDesktopLeft {
  margin-top: 25px;
  text-align: center;
  margin-right: 40%;
  margin-left: 0%;
  display: flex;
  justify-content: center;
}
