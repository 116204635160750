.languageMenuDesktop {
  position: absolute;
  margin-top: 5px;
  margin-left: -13px;
  width: 70px;
  background-color: rgb(0, 90, 140);
  color: white;
  border-radius: 5px;
}
.language {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.languageMenuMobile {
  position: absolute;
  background-color: rgb(0, 90, 140);
  color: white;
  border-radius: 5px;
  width: 60px;
  margin-top: 30px;
  right: 8px;
}
