.textContainer {
  display: flex;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  flex-direction: column;
}
.textContainer__flexMobile {
  display: flex;
  flex-direction: column;
}
.textContainer__flex {
  display: flex;
  flex-direction: row;
}
.textContainer__text {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #444444;
  margin-right: 5px;
}

.buttonContainer {
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
}
.buttonContainer__buttonPosition {
  margin-top: 25px;
  text-align: center;
  margin-right: var(--marginXL);
  margin-left: var(--marginXL);
}
.buttonContainer__buttonStyle {
  width: 180px;
  height: 61px;
  border-radius: 4px;
  border-color: rgb(186, 2, 1);
  background: rgb(186, 2, 1);
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-family: "Gilroy-Medium";
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: white;
}
.buttonContainer__buttonStyle:hover {
  background-color: #f83737;
}
