.labels {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}
.labelsMobile {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}
.hr {
  flex: 1;
  border-width: 0.5;
  text-align: center;
  margin-left: 5px;
}
.hrMobile {
  flex: 1;
  border-width: 0.5;
  text-align: center;
  margin-bottom: 11px;
  margin-left: 15px;
}
