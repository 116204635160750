@import "button.module";

.pbuttonDesktop {
  margin-bottom: 20px;
  height: 50px;
  border-radius: 4px;
  border-color: $beppoBlue;
  color: white;
  background-color: $beppoBlue;
  box-shadow: 1px 1px 5px white;
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  cursor: pointer !important;
  min-width: 185px;
}
.pbuttonMobile {
  border-color: $beppoBlue;
  color: white;
  background-color: $beppoBlue;
  padding-left: 10px;
  margin-bottom: 20px;
  height: 70px;
  border-radius: 4px;
  box-shadow: 1px 1px 5px white;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
}
.buttonDisabled {
  flex: 1;
  height: 50px;
  border-color: $beppoGray;
  background: $beppoBlue;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  line-height: 23px;
  border-radius: 0.25rem;
  font-family: "Gilroy-Medium";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: white;
  padding: 0;
}
.button {
  flex: 1;
  height: 50px;
  border-color: $beppoBlue;
  background: $beppoBlue;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  line-height: 23px;
  border-radius: 0.25rem;
  font-family: "Gilroy-Medium";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: white;
  padding: 0;
}
.contentContainerDesktop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.contentContainerMobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.textSpanDesktop {
  margin-left: 10px;
}
.textSpanMobile {
  margin: 0;
  font-family: "Gilroy-Medium";
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: white;
  margin-top: 2px;
  margin-bottom: 0px;
}
