.background {
  display: flex;
  justify-content: center;
  font-family: "Gilroy-Medium";
  height: 100vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainContainerMobile {
  position: absolute;
  align-items: center;
  margin-top: 30%;
  width: 80%;
}

.mainContainer {
  position: absolute;
  align-items: center;
  margin-top: 15%;
  width: 30%;
}

.button {
  align-self: center;
}
