:root {
  --backgroundColor: rgb(242, 242, 242);
  --marginXL: 20px;
  --paddingXL: 20px;
  --paddingXXL: 30px;
}

.errorFromServer {
  color: #d8000c;
  background-color: #ffbaba;
  background-image: url("https://i.imgur.com/GnyDvKN.png");
  border: 1px solid;
  text-align: center;
  margin: 10px auto;
  padding: 15px 10px 15px 50px;
  border-radius: 40px;
  background-repeat: no-repeat;
  background-position: 10px center;
  max-width: 460px;
  position: absolute;
  left: 40%;
  right: 40%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--backgroundColor);
  height: 100%;
  flex: 1;
  min-height: 100vh;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
input:focus {
  outline: none !important;
  border-color: #fff;
  background: white;
}
textarea:focus {
  outline: none !important;
  border-color: #fff;
  background: white;
}
::placeholder {
  opacity: 0.5;
}
