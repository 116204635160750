@import "../../style/mixins";
@import "../../style/utilities";

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundColor;
  min-height: 100vh;
}

.headingContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.form {
  margin: 0 25px 0px 25px;
  width: -webkit-fill-available;
}
