.navbarLogo {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 9%;
}

.languageSwitchContainer {
  margin-right: 9%;
}
.languageSwitchContainer__currentLanguage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32px;
  cursor: pointer;
  color: #005a92;
}
