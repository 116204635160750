@import "../style/mixins";
@import "../style/utilities";

/* MOBILE */

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(242, 242, 242);
  min-height: 100vh;
}

.headingContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.formMobile {
  margin: 0 20px 20px 20px;
  &__paragraph {
    display: flex;
    margin-bottom: 3px;
    margin-top: 0;
    font-family: $fontGilroyRegular;
    font-size: $fontM;
    line-height: 21px;
  }
  &__paragraphBold {
    display: flex;
    justify-content: flex-end;
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    font-weight: 700;
    color: #444444;
    overflow: hidden;
  }
  &__paragraphDesc {
    margin-bottom: 3px;
    margin-top: 20px;
    font-family: $fontGilroyRegular;
    font-size: $fontM;
  }
  &__mobileLinks {
    color: #005a8c;
    font-family: $fontGilroyMeduim;
    font-weight: bold;
  }
  &__termsRest {
    font-family: $fontGilroyRegular;
    font-size: $fontM;
  }
  &__termsLink {
    color: #005a92;
    font-family: $fontGilroyMeduim;
    font-weight: bold;
    font-size: $fontM;
  }
}

.whiteButtonsMobile {
  display: flex;
  flex-direction: column;

  &__buttonsRowContainer {
    display: flex;
    flex-direction: row;
  }
}

.onlinePaymentButtonPosition {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
}

/* DESKTOP */

.mainContainerDesktop {
  height: 100vh;
  background: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);
}

.formDesktop {
  top: 120px;
  left: 10%;
  right: 15%;
  position: absolute;

  &__subtitle {
    font-family: Gilroy-Regular;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    color: rgb(54, 54, 54);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__scrollableDiv {
    padding-bottom: 25px;
    overflow: auto;
    padding-right: 20px;
  }
  &__detailsAndImgContainer {
    display: flex;
    flex-direction: row;
  }
  &__detailsContainer {
    flex: 4;
  }
  &__paragraph {
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    line-height: 24px;
  }
  &__threeButtonsContainerGasStation {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    width: 75%;
  }
  &__fourButtonsContainer {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    &__icon {
      margin-right: 5px;
    }

    &__imgDesktop {
      align-self: center;
    }
    &__buttonTextWhite {
      font-family: $fontGilroyMeduim;
      font-size: $fontM;
      text-align: center;
      color: white;
      margin-left: 10px;
      margin-bottom: 0;
    }
    &__whiteBtnMargin {
      flex: 0.05;
    }
  }

  &__inputCurrency {
    display: inline;
  }
}
@media (max-width: 1130px) {
  .formDesktop {
    top: 120px;
    left: 5%;
    right: 5%;
    position: absolute;
  }
}

.detailsContainer {
  display: flex;
  justify-content: space-between;

  &__labels {
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    margin-right: 5px;
  }
  &__valueContainer {
    width: 60%;
  }
  &__boldValues {
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    font-weight: 700;
  }
}

.detailsAndImgContainer__imgContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  height: 215px;
}

.formDesktop__links {
  cursor: pointer;
  color: $beppoBlue;
  font-weight: 700;

  &__termsRest {
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    line-height: 24px;
  }
  &__termsLink {
    color: $beppoBlue;
    font-family: $fontGilroyMeduim;
    font-size: $fontM;
    font-weight: 700;
    cursor: pointer;
  }
}

@media (max-width: 845px) {
  .fourButtonsContainer__whiteBtnMargin {
    flex: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.buttonSpacing {
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}


::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #005a8c;
  border-radius: 10px;
}

/* BOTH */

.horizontalLine {
  flex: 1;
  align-self: center;
  border-bottom: 1px solid #c7c7c7;
  margin-right: 15px;
  margin-left: 15px;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}