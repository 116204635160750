.headingLabelDesktopPart1 {
  font-family: Gilroy-Regular;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  color: rgb(204, 6, 5);
  margin-bottom: 40px;
}

.headingLabelDesktopPart2 {
  font-family: Gilroy-Regular;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  margin-bottom: 40px;
}
