.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(242, 242, 242);
  height: 100%;
  flex: 1;
  min-height: 100vh;
}
.absoluteDesktopPosition {
  position: absolute;
  top: 50%;
}
.desktopBackground {
  background-size: 100%;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-family: "Gilroy-Medium";
  height: 100vh;
}
.absoluteMobilePosition {
  position: absolute;
  top: 50%;
  font-size: 20px;
  justify-content: center;
  font-family: "Gilroy-Medium";
  align-self: center;
  display: flex;
}
.absoluteMobilePosition2 {
  position: absolute;
  top: 55%;
  font-size: 20px;
  justify-content: center;
  font-family: "Gilroy-Medium";
  align-self: center;
  display: flex;
}
