@import "../style/mixins";
@import "../style/utilities";

.modalStyleDesktop {
  width: 25%;
  border-radius: 4px;
}

@media (max-width: 1500px) {
  .modalStyleDesktop {
    width: 30%;
    border-radius: 4px;
  }
}

.modalStyleMobile {
  position: absolute;
  top: 15%;
  right: 5%;
  left: 5%;
  border-radius: 4px;
}

.textStyle {
  font-family: $fontGilroyRegular;
  font-size: $fontM;
  line-height: 24px;
}

.infoModalText {
  margin-left: 20px;
  margin-right: 10px;
  font-family: $fontGilroyRegular;
  font-size: $fontM;
}
