@import "../style/mixins";
@import "../style/utilities";

.formMobile {
  margin: 0 20px 0px 0px;
  &__imgContainer {
    margin-right: 10px;
    margin-left: 10px;
  }
}
